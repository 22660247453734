import HeaderDev from "../../Layout/Dev/HeaderDev";
import Footer from "./../../Layout/Footer";
import Header from "./../../Layout/Header";
import Menu from "./../../Layout/Menu";
import CancelItemTable from "./CancelItemTable";
import FoodOverUnderDuration from "./FoodOverUnderDuration";
import { vBoolDev } from "../../Global";

const FnBProduction = () => {
	return (
		<div>
			{
				vBoolDev == true
				?
				<HeaderDev/>
				:
				<Header/>
			}
			<Menu/>
			<div className="content-wrapper">
				<div className="container-fluid">
					<div className="content p-3">
						<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
							<div className="col-12">
								<div>
									{/* <CancelItemTable /> */}
									<FoodOverUnderDuration/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer/>
		</div>
	);
};

export default FnBProduction