import Footer from "../../Layout/Footer"
import Header from "../../Layout/Header"
import Menu from "../../Layout/Menu"
import React from 'react'
import IncomeStatementChart from "./IncomeStatementChart";

const IncomeStatement = () => {
	return (
		<div>
			<Header/>
			<Menu/>
			<div className="content-wrapper">
				<div className="container-fluid">
					<div className="content p-3">
						<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
							<div className="col-12">
								<IncomeStatementChart/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer/>
		</div>
	)
}

export default IncomeStatement