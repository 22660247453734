import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, registerables  } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import { startOfWeek, format } from 'date-fns';
import './StyleFnBService.css'

ChartJS.register(...registerables )

const WeeklyTotalSellout = () => {
	const [TotalSales, SetTotalSales] = useState([]);
	const [loading, setLoading] = useState(false)
	let StartDate = format( startOfWeek( new Date( vGlobalDateNow ), { weekStartsOn: 1 } ), 'dd MMM' )
	let EndDate = format( new Date( vGlobalDateNow ), 'dd MMM' )

	const GetTopItemTable = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/FnB/TotalSales";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							SetTotalSales( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}
		catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}
		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false ); 
	}

	useEffect(() => {
		GetTopItemTable()
	}, [])

	const ColorLastWeek = 'rgb(255, 99, 132)'
	const ColorThisWeek = 'rgb(54, 162, 235)'

	let data = {
		labels: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
		, datasets: [
			{
				label: "LAST WEEK"
				, data: TotalSales.map(row => row.Qty).slice(0, 7)
				, backgroundColor: ColorLastWeek
				, borderColor: ColorLastWeek
				, borderWidth: 1 
			}
			, {
				label: "THIS WEEK"
				, data: TotalSales.map(row => row.Qty).slice(7, 14)
				, backgroundColor: ColorThisWeek
				, borderColor: ColorThisWeek
				, borderWidth: 1
			}
		]
	}

	let option = {
		maintainAspectRatio: false,
		responsive : true,
		scales: {
			x: {
				beginAtZero: false,
			},
			y: {
				beginAtZero: true, 
			}
		},
		plugins: {
			legend: {
				display: true,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 5,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: 'auto'
			},
			tooltip: {
				callbacks: {
					label: ((tooltipItem, data) => {
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 0) {
							return TotalSales.map(row => row.Date).slice(0, 1);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 1) {
							return TotalSales.map(row => row.Date).slice(1, 2);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 2) {
							return TotalSales.map(row => row.Date).slice(2, 3);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 3) {
							return TotalSales.map(row => row.Date).slice(3, 4);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 4) {
							return TotalSales.map(row => row.Date).slice(4, 5);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 5) {
							return TotalSales.map(row => row.Date).slice(5, 6);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 6) {
							return TotalSales.map(row => row.Date).slice(6, 7);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 0) {
							return TotalSales.map(row => row.Date).slice(7, 8);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 1) {
							return TotalSales.map(row => row.Date).slice(8, 9);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 2) {
							return TotalSales.map(row => row.Date).slice(9, 10);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 3) {
							return TotalSales.map(row => row.Date).slice(10, 11);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 4) {
							return TotalSales.map(row => row.Date).slice(11, 12);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 5) {
							return TotalSales.map(row => row.Date).slice(12, 13);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 6) {
							return TotalSales.map(row => row.Date).slice(13, 14);
						}
					}),
					title: function(tooltipItem, data) {
						return '';
					}
				}
			}
		}
	}

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info font-weight-bold">
						WEEKLY FBS PORTION SELLOUT CHART
					</li>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info">
						(In Qty)
					</li>
				</ul>
				<div className="text-info">
					{StartDate} - {EndDate}
				</div>
			</div>
			<div className="card-body">
				<div className="tab-content p-0">
					{
						loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<div className="Chart-Container">
							<Bar
								data={data}
								plugins={[ChartDataLabels]}
								options={option}
								height={null}
								width={null}
							/>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default WeeklyTotalSellout