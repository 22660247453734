import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Menu from "../../Layout/Menu";
import SearchBar from "./SearchBar";

const SalesManagement = () => {
	return (
		<div>
			<Header />
			<Menu />
			<div style={{ minHeight: "100vh" }}>
				<div className="content-wrapper">
					<div className="container-fluid">
						<div className="content px-3 bg-white rounded">
							<SearchBar/>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default SalesManagement