import { faCalendarAlt, faChartBar, faGolfBallTee, faUtensils } from "@fortawesome/free-solid-svg-icons";
import 'toastr/build/toastr.min.css';
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import PersonIcon from '@mui/icons-material/Person';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

import Golf_BG from './../Assets/Golf_BG.jpg';
import Travel_BG from './../Assets/Travel_BG.png';
import Askara_BG from './../Assets/Askara_BG.png';

import Golf_Logo from './../Assets/Golf_Logo.png';
import Travel_Logo from './../Assets/Travel_Logo.jpg';
import Askara_Logo from './../Assets/Askara_Logo.png';
import HIS_Logo from './../Assets/HIS_Logo.jpg';

import { vBoolGolf, vBoolTravel, vBoolDev } from './../Global';

const Menu = () => {
	let vUsername = "";

	if( localStorage.getItem( "Username" ) !== null ){
		vUsername = localStorage.getItem( "Username" ).toUpperCase();
	}

	let vBoolTemp = false;

	if(
		vUsername === "DVD"
		|| vUsername === "MFP"
		|| vUsername === "FERI"
		|| vUsername === "T3DD"
		|| vUsername === "FILIP"
		|| vUsername === "VINO"
		|| vBoolDev === true
	){
		vBoolTemp = true;
	}

	let vBG = Askara_BG;
	let vLogo = Askara_Logo;
	let vTitle = "ASKARA";

	if( vBoolGolf && vBoolTravel ){
	}
	else if( vBoolGolf ){
		vBG = Golf_BG;
		vLogo = Golf_Logo;
		vTitle = "GM360";
	}
	else if( vBoolTravel ){
		vBG = Travel_BG;
		vLogo = HIS_Logo;
		vTitle = "ITM";
	}
	
	const circleStyle = {
		width: '12px'
		, height: '12px'
		, borderRadius: '50%'
		, boxSizing: 'border-box'
		, backgroundColor: 'black'
		, zIndex: 1
		, justifyContent: 'center'
		, alignItems: 'center'
	};

	return (
		<div>
			{
				vBoolGolf == true ?
				<aside className="main-sidebar elevation-4" style={{ backgroundImage: `url(${Golf_BG})` }}>
					<Link to={`/`} className="brand-link" style={{ borderBottom: "1px solid black" }}>
						<img
							src={vLogo}
							className="brand-image img-circle elevation-3"
							style={{ opacity: ".8" }}
						/>
						<span className="brand-text font-weight-bold" style={{ color: "black" }}>{vTitle} Dashboard</span>
					</Link>
					<div className="sidebar">
						<nav className="mt-2">
							<ul
								className="nav nav-pills nav-sidebar flex-column"
								data-widget="treeview"
								role="menu"
								data-accordion="false"
							>
								<li className="nav-item">
									<Link
										className="nav-link"
										onClick={(e) => window.location.replace("/Sign/Out")}
									>
										<LogoutIcon
											style={{ color: "red", marginLeft: 7, fontSize: "medium" }}
										/>
										<span
											className="brand-text font-weight-light"
											style={{ marginLeft: 6, color: "red", fontSize: "16px" }}
										>
											Sign Out
										</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										onClick={(e) => window.location.replace("/Password/Change")}
									>
										<LockResetIcon
											style={{ color: "red", marginLeft: 6, fontSize: "medium" }}
										/>
										<span
											className="brand-text font-weight-light"
											style={{ marginLeft: 6, color: "red", fontSize: "16px" }}
										>
											Change Password
										</span>
									</Link>
								</li>
								{
									( vBoolTemp && vBoolGolf ) ?
										<li className="nav-item">
											<Link 
												to={`/ExecutiveHighlight`}
												className="nav-link"
												onClick={(e) => window.scrollTo(0, 0)}
											>
												<FontAwesomeIcon 
													className="nav-icon"
													icon={faChartBar}
													style={{ color: "black" }}
												/>
												<p 
													className="brand-text font-weight-bold"
													style={{ color: "black" }}
												>
													Executive Highlight
												</p>
											</Link>
										</li>
									:
										<>
										</>
								}
								<li className="nav-item">
									<Link 
										to={`/NumberOfPlayer`}
										className="nav-link"
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<PersonIcon
											style={{ color: "black" }}
										/>
										<p 
											className="brand-text font-weight-bold"
											style={{ color: "black" }}
										>
											&nbsp;Number Of Player
										</p>
									</Link>
								</li>
								<li className="nav-item">
									<Link 
										to={`/GolfOperations`} 
										className="nav-link"
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<FontAwesomeIcon
											className="nav-icon"
											icon={faGolfBallTee} 
											style={{ color: "black" }}
										/>
										<p 
											className="brand-text font-weight-bold"
											style={{ color: "black" }}
										>
											Golf Operations
										</p>
									</Link>
								</li>
								<li className="nav-item">
									<Link 
										to={`/Reservation`}
										className="nav-link"
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<FontAwesomeIcon
											className="nav-icon"
											icon={faCalendarAlt}
											style={{ color: "black" }}
										/>
										<p 
											className="brand-text font-weight-bold"
											style={{ color: "black" }}
										>
											Reservation
										</p>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										to={`/FnBServices`}
										className="nav-link"
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<FontAwesomeIcon
											className="nav-icon"
											icon={faUtensils}
											style={{ color: "black" }}
										/>
										<p
											className="brand-text font-weight-bold"
											style={{ color: "black" }}
										>
											F&B Services
										</p>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										to={`/FnBProduction`}
										className="nav-link"
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<OutdoorGrillIcon
											style={{ color: "black" }}
										/>
										<p 
											className="brand-text font-weight-bold"
											style={{ color: "black" }}
										>
											&nbsp;F&B Production
										</p>
									</Link>
								</li>
								{
									( vBoolTemp && vBoolGolf && vBoolDev == false ) ?
										<li className="nav-item">
											<Link className="nav-link">
												<p
													className="font-weight-bold"
													style={{ color: "black" }}
												>
													<PaidOutlinedIcon
														style={{ color: "black" }}
													/>
													&nbsp;Financial Report
													<i className="right fas fa-angle-left">
													</i>
												</p>
											</Link>
											<ul className="nav nav-treeview">
												<li className="nav-item">
													<Link to={`/IncomeStatement`} className="nav-link">
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<div style={circleStyle}>
															</div>
															<p style={{ marginLeft: '8px', color: "black" }}>
																Income Statement
															</p>
														</div>
													</Link>
												</li>
												<li className="nav-item">
													<Link to={`/BalanceSheet`} className="nav-link">
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<div style={circleStyle}>
															</div>
															<p style={{ marginLeft: '8px', color: "black" }}>
																Balance Sheet
															</p>
														</div>
													</Link>
												</li>
												<li className="nav-item">
													<Link to={`/Revenue`} className="nav-link">
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<div style={circleStyle}>
															</div>
															<p style={{ marginLeft: '8px', color: "black" }}>
																Revenue
															</p>
														</div>
													</Link>
												</li>
											</ul>
										</li>
									:
										<>
										</>
								}
							</ul>
						</nav>
					</div>
				</aside>
				:
				null
			}
			{
				vBoolTravel == true ?
				<aside className="main-sidebar sidebar-dark-primary elevation-4">
					<Link to={`/`} className="brand-link">
						<img
							src={vLogo}
							className="brand-image img-circle elevation-3"
							style={{ opacity: ".8" }}
						/>
						<span className="brand-text font-weight-light">
							{vTitle} Dashboard
						</span>
					</Link>
					<div className="sidebar">
						<nav className="mt-2">
							<ul
								className="nav nav-pills nav-sidebar flex-column"
								data-widget="treeview"
								role="menu"
								data-accordion="false"
							>
								<li className="nav-item">
									<Link
										className="nav-link"
										onClick={(e) => window.location.replace("/Sign/Out")}
									>
										<LogoutIcon
											style={{ color: "red", marginLeft: 7, fontSize: "medium" }}
										/>
										<span
											className="brand-text font-weight-light"
											style={{ marginLeft: 6, color: "red", fontSize: "16px" }}
										>
											Sign Out
										</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										onClick={(e) => window.location.replace("/Password/Change")}
									>
										<LockResetIcon
											style={{ color: "red", marginLeft: 6, fontSize: "medium" }}
										/>
										<span
											className="brand-text font-weight-light"
											style={{ marginLeft: 6, color: "red", fontSize: "16px" }}
										>
											Change Password
										</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										to={`/SalesManagement`}
										className="nav-link"
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<p> 
											Sales Management
										</p>
									</Link>
								</li>
							</ul>
						</nav>
					</div>
				</aside>
				:
				null
			}
		</div>
	);
};

export default Menu;