import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../../Global';
import axios from 'axios';
import toastr from 'toastr';
import format from 'date-fns/format';

ChartJS.register(...registerables )

const MemberVsGuestDev = () => {
	const [ Loading, setLoading ] = useState( false )
	const [ DataTemp, SetDataTemp ] = useState( [] );
	const date = new Date( vGlobalDateNow );

	const GetMemberVsGuestData = async() => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Main/PlayerType/Dev"
			const vData = {
				Action: {
					Value: "Search"
				},
				Input: {
					Date: {
						Value: format( date, 'dd-MMM-yyyy' ),
					},
				},
			}
			const vConfig = {
				headers: {
					"Content-Type": "application/json"
					, Authentication: `${localStorage.getItem("Authentication")}`,
				},
			}
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							SetDataTemp( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false );
	}

	let ChartData = {
		labels: DataTemp.map( Value => Value.Date ),
		datasets: [
			{
				label: 'MEMBER'
				, data: DataTemp.map( Value => Value.Member )
				, backgroundColor: 'rgb( 50, 168, 82 )'
				, borderColor: 'rgb( 50, 168, 82 )'
				, borderWidth: 1
				, stack: 1
			}
			, {
				label: 'GUEST'
				, data: DataTemp.map( Value => Value.Guest )
				, backgroundColor: 'rgb( 219, 219, 26 )'
				, borderColor: 'rgb( 219, 219, 26 )'
				, borderWidth: 1
				, stack: 1
			}
		]
	}

	let ChartOption = {
		maintainAspectRatio: false
		, responsive : true
		, plugins: {
			legend: {
				display: true
				, position: 'top'
				, align: 'center'
				, labels: {
					boxHeight: 10
					, boxWidth: 10
					, textAlign: 'left'
				}
			}
			, tooltip: {
				callbacks: {
					title: function(tooltipItem, data) {
						return '';
					}
				}
			}
		}
	}

	useEffect(() => {
		GetMemberVsGuestData()
	},[])
	
	return (
		<div className="col-12 col-lg-6">
			<div className="card bg-gradient-light">
				<div className="card-header">
					<h3 className="card-title text-success font-weight-bold">
						MEMBER VS GUEST
					</h3>
					<div className="card-tools">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								TODAY
							</li>
						</ul>
					</div>
				</div>
				<div className="CardBody-Chart">
					<div className="tab-content p-0">
						{
							Loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"/>
								</div>
							:
							<div className="Chart-Container">
								<Bar
									data={ChartData}
									plugins={[ChartDataLabels]}
									options={ChartOption}
									height={null}
									width={null}
								/>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default MemberVsGuestDev