import { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import format from 'date-fns/format';

const BuggyMasterTable = () => {
	const [ BuggyMaster, SetBuggyMaster ] = useState( [] );
	const [ loading, setLoading ] = useState( false );
	const TotalUnit = BuggyMaster.map( Value => Value.TotalUnit ).slice( 7, 14 );
	const BuggyMaintenance = BuggyMaster.map( Value => Value.BuggyMaintenance ).slice( 7, 14 );
	const BuggyActive = BuggyMaster.map( Value => Value.BuggyActive ).slice( 7, 14 );
	const BuggyPaired = BuggyMaster.map( Value => Value.BuggyPaired ).slice( 7, 14 );
	const BuggyUnpaired = BuggyMaster.map( Value => Value.BuggyUnpaired ).slice( 7, 14 );
	const BuggyStandby = BuggyMaster.map( Value => Value.BuggyStandby ).slice( 7, 14 );
	const PlayerAM = BuggyMaster.map( Value => Value.PlayerAM ).slice( 7, 14 );
	const PlayerPM = BuggyMaster.map( Value => Value.PlayerPM ).slice( 7, 14 );
	
	const PairingRateAM = BuggyActive.map( function(val, index ) {
		const rate = ( Math.round( ( (+PlayerAM[index]/ 2 ) / +val * 100) * 100) / 100 );
		return isFinite( rate ) ? rate : 0;
	});

	const PairingRatePM = BuggyActive.map( function( val, index ) {
		const rate = ( Math.round( ( (+PlayerPM[index]/ 2 ) / +val * 100) * 100) / 100 );
		return isFinite( rate ) ? rate : 0;
	});

	const GetBuggyMaster = async () => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try {
			const vURL = vAPIURL + "/Golf/Operation/Buggy";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios 
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						for (var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push ( vResponse.data.Message[i] );
						}

						if( vStatus === 1 ){
							SetBuggyMaster( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}
		catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false )
	}

	useEffect( () => {
		GetBuggyMaster()
	}, [] )

	function createData( DayType ) {
 		return { DayType };
	}

	const DayType = [
		createData( 'MON' )
		, createData( 'TUE' )
		, createData( 'WED' )
		, createData( 'THU' )
		, createData( 'FRI' )
		, createData( 'SAT' )
		, createData( 'SUN' )
	];
	
	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<h3 className="card-title text-success font-weight-bold">
					BUGGY MASTER TABLE
				</h3>
				<div className="card-tools">
					<ul className="nav nav-pills ml-auto">
						<li className="nav-item text-info font-weight-bold">
							THIS WEEK
						</li>
					</ul>
				</div>
			</div>
			{loading ?
				<div className="d-flex justify-content-center">
					<i className="fas fa-3x fa-sync-alt fa-spin"/>
				</div>
				:
				<div className="position-relative">
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableCell 
									sx={{ 
										width: 200
										, fontWeight: 'bold'
									}}
									align="left" 
									variant="head"
								>
									STATUS
								</TableCell>
								{DayType.map( ( Value ) => (
									<TableCell 
										sx={{ 
											width: 100
											, fontWeight: 'bold'
										}}
										align="left" 
										variant="head"
									>
										{Value.DayType}
									</TableCell>
								) ) }
							</TableHead>
							<TableHead>
								<TableCell 
									sx={{ 
										width: 200 
									}}
									align="left"
									variant="head"
								>
									TOTAL UNIT
								</TableCell>
								{TotalUnit.map( ( Value ) => (
									<TableCell 
										sx={{ 
											width: 100
										}}
										align="left"
										variant="head"
									>
										{Value}
									</TableCell>
								) ) }
							</TableHead>
							<TableHead>
								<TableCell 
									sx={{ 
										width: 200 
									}}
									align="left"
									variant="head"
								>
									UNDER MAINTENANCE
								</TableCell>
								{BuggyMaintenance.map( ( Value ) => (
									<TableCell 
										sx={{ 
											width: 100
										}}
										align="left"
										variant="head"
									>
										{Value}
									</TableCell>
								) ) }
							</TableHead>
							<TableHead>
								<TableCell 
									sx={{ 
										width: 200 
										, fontWeight: 'bold'
									}}
									align="left"
									variant="head"
								>
									TOTAL ACTIVE UNIT
								</TableCell>
								{BuggyActive.map( ( Value ) => (
									<TableCell 
										sx={{ 
											width: 100 
											, fontWeight: 'bold'
										}}
										align="left"
										variant="head"
									>
										{Value}
									</TableCell>
								) ) }
							</TableHead>
							<TableHead 
								rowSpan={6}
							>
								<TableCell 
									sx={{ border:0 }}
								>
								</TableCell>
							</TableHead>
							<TableHead>
								<TableCell 
									sx={{ 
										width: 200 
									}}
									align="left"
									variant="head"
								>
									PAIRED
								</TableCell>
								{BuggyPaired.map( ( Value ) => (
									<TableCell 
										sx={{ 
											width: 100
										}}
										align="left"
										variant="head"
									>
										{Value}
									</TableCell>
								) ) }
							</TableHead>
							<TableHead>
								<TableCell 
									sx={{ 
										width: 200
									}}
									align="left"
									variant="head"
								>
									UNPAIRED
								</TableCell>
								{BuggyUnpaired.map( ( Value ) => (
									<TableCell 
										sx={{ 
											width: 100
										}}
										align="left"
										variant="head"
									>
										{Value}
									</TableCell>
								))}
							</TableHead>
							<TableHead>
								<TableCell 
									sx={{ 
										width: 200
									}}
									align="left"
									variant="head"
								>
									STAND BY
								</TableCell>
								{BuggyStandby.map( ( Value ) => (
									<TableCell 
										sx={{ 
											width: 100
										}}
										align="left"
										variant="head"
									>
										{Value}
									</TableCell>
								) ) }
							</TableHead>
							<TableHead 
								rowSpan={6}
							>
								<TableCell 
									sx={{ border:0 }}
								>
								</TableCell>
							</TableHead>
							<TableHead>
								<TableCell 
									sx={{ 
										width: 200
									}}
									align="left"
									variant="head"
								>
									BUGGY UTILIZATION RATE AM
								</TableCell>
								{PairingRateAM.map( ( Value ) => (
									<TableCell 
										sx={{ 
											width: 100
										}}
										align="left"
										variant="head"
									>
										{Value} %
									</TableCell>
								) ) }
							</TableHead>
							<TableHead>
								<TableCell 
									sx={{ 
										width: 200
									}}
									align="left"
									variant="head"
								>
									BUGGY UTILIZATION RATE PM
								</TableCell>
								{PairingRatePM.map( ( Value ) => (
									<TableCell 
										sx={{ 
											width: 100
										}}
										align="left"
										variant="head"
									>
										{Value} %
									</TableCell>
								) ) }
							</TableHead>
						</Table>
					</TableContainer>
				</div>
			}
		</div>
	);
}
export default BuggyMasterTable 