import React from "react"
import ExecutiveHighlight from "./Module/ExecutiveHighlight"
import NumberOfPlayer from "./Module/NumberOfPlayer"
import GolfOperations from "./Module/GolfOperations"
import Reservation from "./Module/Reservation"
import FnBServices from "./Module/Fnbservices"
import FnBProduction from "./Module/Fnbproduction"
import ForgotPassword from "./Module/ForgotPassword"
import ForgotPasswordC from "./Module/ForgotPasswordC"
import PasswordChange from "./Module/PasswordChange"
import SignOTP from "./Module/SignOTP"
import SignIn from "./Module/SignIn"
import SignOut from "./Module/SignOut"
import SalesManagement from "./Module/SalesManagement"
import IncomeStatement from "./Module/IncomeStatement"
import BalanceSheet from "./Module/BalanceSheet"
import Revenue from "./Module/Revenue"
import { Route, Routes, Navigate } from "react-router-dom"
import { vBoolGolf, vBoolTravel, vBoolDev } from "./Global";
import MainPage from "./Module/MainPage"

const Router = () => {
	var vAuthentication = "";

	if( localStorage.getItem( "Authentication" ) !== null ){
		vAuthentication = localStorage.getItem( "Authentication" );
	}

	var vUsername = "";

	if( localStorage.getItem( "Username" ) !== null ){
		vUsername = localStorage.getItem( "Username" ).toUpperCase();
	}

	var vBoolTemp = false;

	if(
		vUsername === "DVD"
		|| vUsername === "MFP"
		|| vUsername === "FERI"
		|| vUsername === "T3DD"
		|| vUsername === "FILIP"
		|| vUsername === "VINO"
		|| vBoolDev === true
	){
		vBoolTemp = true;
	}

	return (
		<Routes>
			<Route path="/" element={ ( vAuthentication !== "" ) ? <MainPage /> : <Navigate to="/Sign/In" /> } />
			<Route path="/ExecutiveHighlight" element={ ( vAuthentication !== "" && vBoolTemp && vBoolGolf ) ? <ExecutiveHighlight /> : <Navigate to="/Sign/In" /> } />
			<Route path="/IncomeStatement" element={ ( vAuthentication !== "" && vBoolTemp && vBoolGolf ) ? <IncomeStatement /> : <Navigate to="/Sign/In" /> } />
			<Route path="/Revenue" element={ ( vAuthentication !== "" && vBoolTemp && vBoolGolf ) ? <Revenue /> : <Navigate to="/Sign/In" /> } />
			<Route path="/BalanceSheet" element={ ( vAuthentication !== "" && vBoolTemp && vBoolGolf ) ? <BalanceSheet /> : <Navigate to="/Sign/In" /> } />
			<Route path="/NumberOfPlayer" element={ ( vAuthentication !== "" && vBoolGolf ) ? <NumberOfPlayer /> : <Navigate to="/Sign/In" /> } />
			<Route path="/GolfOperations" element={ ( vAuthentication !== "" && vBoolGolf ) ? <GolfOperations /> : <Navigate to="/Sign/In" /> } />
			<Route path="/Reservation" element={ ( vAuthentication !== "" && vBoolGolf ) ? <Reservation /> : <Navigate to="/Sign/In" /> } />
			<Route path="/FnBServices" element={ ( vAuthentication !== "" && vBoolGolf ) ? <FnBServices /> : <Navigate to="/Sign/In" /> } />
			<Route path="/FnBProduction" element={ ( vAuthentication !== "" && vBoolGolf ) ? <FnBProduction /> : <Navigate to="/Sign/In" /> } />
			<Route path="/SalesManagement" element={ ( vAuthentication !== "" && vBoolTravel ) ? <SalesManagement/> : <Navigate to="/Sign/In" /> } />
			<Route path="/Password/Forgot/Request" element={ ( vAuthentication === "" ) ? <ForgotPassword /> : <Navigate to="/Sign/In" /> } />
			<Route path="/Password/Forgot/Confirm" element={ ( vAuthentication === "") ? <ForgotPasswordC /> : <Navigate to="/Sign/In" />} />
			<Route path="/Password/Change" element={ ( vAuthentication !== "" ) ? <PasswordChange /> : <Navigate to="/Sign/In" /> } />
			<Route path="/Sign/In" element={ ( vAuthentication === "" ) ? <SignIn /> : <Navigate to="/" /> } />
			<Route path="/Sign/Out" element={ ( vAuthentication !== "" ) ? <SignOut /> : <Navigate to="/Sign/In" /> } />
			<Route path="/Sign/OTP" element={ ( vAuthentication !== "" ) ? <SignOTP /> : <Navigate to="/Sign/In" /> } />
		</Routes>
	)
}

export default Router