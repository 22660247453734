import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { vAPIURL, vGlobalDateNow } from '../../../Global';
import axios from 'axios';
import toastr from 'toastr';
import TablePaginationUnstyled, { tablePaginationUnstyledClasses as classes } from '@mui/base/TablePaginationUnstyled';
import { styled } from '@mui/system';
import { startOfWeek,endOfWeek, getISOWeek } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import format from 'date-fns/format';
import DatePicker from 'react-datepicker';

const CompareFoodDev = () => {

	const NowDate = new Date( vGlobalDateNow )
	let LastWeekDate = new Date( vGlobalDateNow )
	LastWeekDate = LastWeekDate.setDate( LastWeekDate.getDate() - 7 )
	const [DataWeek1, SetDataWeek1] = useState( [] )
	const [DataWeek2, SetDataWeek2] = useState( [] )
	const [Loading, SetLoading] = useState( false )
	const [WeekStartDate1, SetWeekStartDate1] = useState( startOfWeek( LastWeekDate, { weekStartsOn: 1 } ) )
	const [WeekEndDate1, SetWeekEndDate1] = useState( endOfWeek( LastWeekDate, { weekStartsOn: 1 } ) )
	const [WeekStartDate2, SetWeekStartDate2] = useState( startOfWeek( NowDate, { weekStartsOn: 1 } ) )
	const [WeekEndDate2, SetWeekEndDate2] =  useState( endOfWeek( NowDate, { weekStartsOn: 1 } ) )
	const [TypeMenu, SetTypeMenu] = useState( "All" )
	const [Status, SetStatus] = useState( 1 )

	let vNumberFormat = new Intl.NumberFormat(
		'en-US'
		, {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		}
	)
	
	const GetCompareData = async () => {
		SetLoading( true )
		let vMessage = []
		let StatusTemp = 1

		try {
			const vURL = vAPIURL + "/Golf/Executive/Compare/Dev"
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					StartDate1: {
						Value: format( WeekStartDate1, 'dd-MMM-yyyy' ),
					},
					EndDate1: {
						Value: format( WeekEndDate1, 'dd-MMM-yyyy' ),
					},
					StartDate2: {
						Value: format( WeekStartDate2, 'dd-MMM-yyyy' ),
					},
					EndDate2: {
						Value: format( WeekEndDate2, 'dd-MMM-yyyy' ),
					},
					TypeMenu: {
						Value: TypeMenu,
					},
				},
			}
			const vConfig = {
				headers: {
					"Content-Type": "application/json"
					, Authentication: `${localStorage.getItem("Authentication")}`
				}
			}
			
			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						SetStatus( vResponse.data.Status )
						StatusTemp = 1

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[i] )
						}

						if( Status === 1 ){
							SetDataWeek1( vResponse.data.Output.ResultWeek1 )
							SetDataWeek2( vResponse.data.Output.ResultWeek2 )
						}
					}
				)
				.catch(
					(vError) => {
						SetStatus( 2 )
						StatusTemp = 2
						vMessage.push( "Error" )
					}
				);
		}catch( vError ){
			SetStatus( 2 )
			StatusTemp = 2
			vMessage.push( "Error" )
		}

		if( vMessage.length > 0 ){
			if( StatusTemp == 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) )
			}
		}
		SetLoading( false )
	}
	
	const SetterWeek1 = (e) => {
		SetWeekStartDate1( startOfWeek( e, { weekStartsOn: 1 } ) )
		SetWeekEndDate1( endOfWeek( e, { weekStartsOn: 1 } ) )
	}
	
	const SetterWeek2 = (e) => {
		SetWeekStartDate2( startOfWeek( e, { weekStartsOn: 1 } ) )
		SetWeekEndDate2( endOfWeek( e, { weekStartsOn: 1 } ) )
	}

	let SumWeek1Qty = 0
	let SumWeek1Percentage = 0
	let SumWeek2Qty = 0
	let SumWeek2Percentage = 0

	const [page, setPage] = useState( 0 )
	const [rowsPerPage, setRowsPerPage] = useState( 5 )
	const [StartCount, SetStartCount] = useState( 1 )
	const [EndCount, SetEndCount] = useState( rowsPerPage )

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
		if( page < newPage ){
			if( page + 1 == newPage ){
				SetStartCount( ( StartCount + rowsPerPage ) )
				if( ( EndCount + rowsPerPage ) > ArrayLength ){
					SetEndCount( ArrayLength )
				}
				else{
					SetEndCount( ( EndCount + rowsPerPage ) )
				}
			}
			else{
				SetStartCount( (newPage * rowsPerPage) + 1 )
				SetEndCount( ArrayLength )
			}
		}
		else if( page > newPage ){
			if( page - 1 == newPage ){
				SetStartCount( ( StartCount - rowsPerPage ) )
				if( ( newPage + 1 ) * rowsPerPage > ArrayLength ){
					SetEndCount( ArrayLength );
				}
				else{
					SetEndCount( ( newPage + 1 ) * rowsPerPage );
				}
			}
			else{
				SetStartCount( 1 )
				SetEndCount( rowsPerPage )
			}
		}
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage( parseInt( event.target.value ) )
		SetStartCount( 1 )
		if( event.target.value == -1 ){
			SetEndCount( ArrayLength )
		}
		else{
			SetEndCount( parseInt( event.target.value ) )
		}
		setPage( 0 )
	}
	
	const CustomTablePagination = styled(TablePaginationUnstyled)`
		& .${classes.toolbar} {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 7px;

			@media (min-width: 768px) {
				flex-direction: row;
				align-items: center;
			}
		}

		& .${classes.selectLabel} {
			margin: 0;
			gap: 7px;
		}

		& .${classes.displayedRows} {
			margin: 0;
			gap: 7px;

			@media (min-width: 768px) {
				margin-left: auto;
			}
		}

		& .${classes.spacer} {
			display: none;
		}

		& .${classes.actions} {
			display: flex;
			gap: 0.25rem;
		}
	`;
	
	let ArrayLength = 0
	if( DataWeek1.length >= DataWeek2.length ){
		ArrayLength = DataWeek1.length
	}
	else{
		ArrayLength = DataWeek2.length
	}

	const DataWeek1Temp = (
		rowsPerPage > 0 
		? DataWeek1.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
		: DataWeek1
	)

	const DataWeek2Temp = (
		rowsPerPage > 0 
		? DataWeek2.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
		: DataWeek2
	)

	DataWeek1Temp.map(
		(Value) => {
			SumWeek1Percentage += parseFloat( Value.PercentageWeek1 )
			SumWeek1Qty += parseInt( Value.QtyWeek1 )
		}
	)
	
	DataWeek2Temp.map(
		(Value) => {
			SumWeek2Percentage += parseFloat( Value.PercentageWeek2 )
			SumWeek2Qty += parseInt( Value.QtyWeek2 )
		}
	)

	useEffect(() => {
		GetCompareData();
	}, [ WeekStartDate1, WeekEndDate1, WeekStartDate2, WeekEndDate2, TypeMenu ]);

	return (
		<div className="row p-1">
			<div className="col-12 col-lg-6">
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<h3 className="card-title text-success font-weight-bold">
								COMPARE TOP SELLING F&B
							</h3>
						</ul>
						<div className="dropdown show" style={{ marginTop: '7px' }}>
							<a className="btn btn-primary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width:'150px' }}>
								{TypeMenu}
							</a>
							<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
								<button 
									className="dropdown-item" 
									type="button"
									onClick={() => {
										SetTypeMenu("All")
									}}
								>
									All
								</button>
								<button 
									className="dropdown-item" 
									type="button"
									onClick={() => {
										SetTypeMenu("Food")
									}}
								>
									Food
								</button>
								<button
									className="dropdown-item" 
									type="button"
									onClick={() => {
										SetTypeMenu("Beverage")
									}}
								>
									Beverage
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<label>
									WEEK A
								</label>
							</div>
							<div className="col-12">
								<DatePicker
									dateFormat="I-R"
									selected={ WeekStartDate1 }
									onChange={e => SetterWeek1(e)}
									className="form-control"
									value={ WeekStartDate1 }
									showWeekNumbers
									locale={enGB}
									startDay={1}
								/>
							</div>
						</div>
						<CustomTablePagination
							rowsPerPageOptions={[5, 10, 20, { label: 'All', value: -1 }]}
							count={ArrayLength}
							rowsPerPage={rowsPerPage}
							page={page}
							slotProps={{
								select: {
								'aria-label': 'rows per page',
								},
								actions: {
									showFirstButton: true,
									showLastButton: true,
								},
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</div>
					{
						Loading ?
							<div className="d-flex justify-content-center">
								<i className="fas fa-3x fa-sync-alt fa-spin"/>
							</div>
						:
						Status == 1 ?
						<div>
							<TableContainer component={Paper}>
								<Table aria-label="Table Timing">
									<TableHead sx={{ minHeight:10 }}>
										<TableRow>
											<TableCell 
												sx={{ 
													border: 1
													, width: 20
													, fontWeight: 'bold'
													, padding: 0.5 
												}} 
												align="center"
											>
												#
											</TableCell>
											<TableCell 
												sx={{
													border: 1
													, width: 240
													, fontWeight: 'bold'
													, padding: 0.5
												}}
												align="left"
											>
												Item
											</TableCell>
											<TableCell 
												sx={{
													border: 1
													, width: 100
													, fontWeight: 'bold'
													, padding:0.5
												}}
												align="center"
											>
												Qty
											</TableCell>
											<TableCell 
												sx={{
													border: 1
													, width: 60
													, fontWeight: 'bold'
													, padding:0.5
												}} 
												align="center"
											>
												%
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											( rowsPerPage > 0
												? DataWeek1.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
												: DataWeek1
											).map(
												(Value) => (
													<TableRow key={Value.MenuName}>
														<TableCell sx={{ border: 1, padding: 0.5  }} align="center">
															{Value.No}
														</TableCell> 
														<TableCell sx={{ border: 1, padding:0.5 }} align="left">
															{Value.MenuName}
														</TableCell>
														<TableCell sx={{ border: 1, padding: 0.5  }} align="center">
															{Value.QtyWeek1}
														</TableCell>
														<TableCell sx={{ border: 1, padding: 0.5 }} align="center">
															{Value.PercentageWeek1} %
														</TableCell>
													</TableRow>
												)
											)
										}
									</TableBody>
									<TableBody>
										<TableRow>
											<TableCell
												sx={{
													border: 1
													, padding: 0.5
												}}
												align="center"
												colSpan={2}
											>
												Total Item
											</TableCell> 
											<TableCell
												sx={{
													border: 1
													, padding: 0.5
												}}
												align="center"
											>
												{ SumWeek1Qty }
											</TableCell>
											<TableCell
												sx={{
													border: 1
													, padding: 0.5
												}}
												align="center"
											>
												{ vNumberFormat.format( SumWeek1Percentage ) } %
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						:
						<div>
							<center>
								Invalid Data!
							</center>
						</div>
					}
				</div>
			</div>
			<div className="col-12 col-lg-6">
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<h3 className="card-title text-success font-weight-bold">
								COMPARE TOP SELLING F&B
							</h3>
						</ul>
						<div className="dropdown show" style={{ height: '35px', marginTop: '7px' }}>
							&nbsp;
						</div>
						<div className="row">
							<div className="col-12">
								<label>
									WEEK A+
								</label>
							</div>
							<div className="col-12">
								<DatePicker
									dateFormat="I-R"
									selected={ WeekStartDate2 }
									onChange={e => SetterWeek2(e)}
									className="form-control"
									value={ WeekStartDate2 }
									showWeekNumbers
									locale={enGB}
									startDay={1}
								/>
							</div>
						</div>
						<ul className="nav nav-pills ml-auto" style={{ height: 29 }}>
							<li className="nav-item justify-content-center" style={{ paddingTop: 5 }}>
								Rows per page: {StartCount} - {EndCount} of {ArrayLength}
							</li>
						</ul>
					</div>
					{
						Loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"/>
						</div>
						:
						Status == 1 ?
						<div>
							<TableContainer component={Paper}>
								<Table aria-label="Table Timing">
									<TableHead sx={{ minHeight:10 }}>
										<TableRow>
											<TableCell
												sx={{
													border: 1
													, width: 20
													, fontWeight: 'bold'
													, padding:0.5
												}}
												align="center"
											>
												#
											</TableCell>
											<TableCell
												sx={{
													border: 1
													, width: 240
													, fontWeight: 'bold'
													, padding:0.5
												}}
												align="left"
											>
												Item
											</TableCell>
											<TableCell
												sx={{
													border: 1
													, width: 100
													, fontWeight: 'bold'
													, padding:0.5
												}}
												align="center"
											>
												Qty
											</TableCell>
											<TableCell
												sx={{
													border: 1
													, width: 100
													, fontWeight: 'bold'
													, padding:0.5
												}}
												align="center"
											>
												%
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											( rowsPerPage > 0
												? DataWeek2.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
												: DataWeek2
											).map(
												( Value ) => (
												<TableRow key={Value.MenuName} sx={{ border: 1 }}>
													<TableCell
														sx={{
															border: 1
															, padding: 0.5
														}}
														align="center"
													>
														{Value.No}
													</TableCell>
													<TableCell
														sx={{
															border: 1
															, padding: 0.5
														}}
														align="left"
													>
														{Value.MenuName}
													</TableCell>
													<TableCell
														sx={{
															border: 1
															, padding: 0.5
														}}
														align="center"
													>
														{Value.QtyWeek2}
													</TableCell>
													<TableCell
														sx={{
															border: 1
															, padding: 0.5
														}}
														align="center"
													>
														{Value.PercentageWeek2} %
													</TableCell>
												</TableRow>
												)
											)
										}
									</TableBody>
									<TableBody>
										<TableRow>
											<TableCell
												sx={{
													border: 1
													, padding:0.5
												}}
												align="center"
												colSpan={2}
											>
												Total Item
											</TableCell> 
											<TableCell
												sx={{
													border: 1
													, padding: 0.5
												}}
												align="center"
											>
												{ SumWeek2Qty }
											</TableCell>
											<TableCell
												sx={{
													border: 1
													, padding: 0.5
												}}
												align="center"
											>
												{ vNumberFormat.format( SumWeek2Percentage ) } %
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						:
						<div>
							<center>
								Invalid Data!
							</center>
						</div>
					}
				</div>
			</div>
		</div>
	);
}
export default CompareFoodDev 