import React from "react";
import { Avatar } from "@mui/material";

const Member = () => {
	return (
		<div className="col-12 col-lg-8">
			<div className="card bg-gradient-light">
				<div className="card-header">
					<h3 className="card-title text-success font-weight-bold">
						MEMBER
					</h3>
					<div className="card-tools">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
							</li>
						</ul>
					</div>
				</div>
				<div className="card-body p-0">
					<ul className="users-list clearfix">
						<li>
							<div className="d-flex justify-content-center">
								<Avatar sx={{ margin: 1 }} src={"https://adminlte.io/themes/v3/dist/img/user1-128x128.jpg"}/>
							</div>
							<a className="users-list-name">
								Gianno Everly
							</a>
							<span className="users-list-date">
								Today
							</span>
						</li>
						<li>
							<div className="d-flex justify-content-center">
								<Avatar sx={{ margin: 1 }} src={"https://adminlte.io/themes/v3/dist/img/user3-128x128.jpg"}/>
							</div>
							<a className="users-list-name">
								Brooklyn Tirrell
							</a>
							<span className="users-list-date">
								Yesterday
							</span>
						</li>
						<li>
							<div className="d-flex justify-content-center">
								<Avatar sx={{ margin: 1 }} src={"https://adminlte.io/themes/v3/dist/img/user4-128x128.jpg"}/>
							</div>
							<a className="users-list-name">
								Nevaeh Walker
							</a>
							<span className="users-list-date">
								2 Dec
							</span>
						</li>
						<li>
							<div className="d-flex justify-content-center">
								<Avatar sx={{ margin: 1 }} src={"https://adminlte.io/themes/v3/dist/img/user5-128x128.jpg"}/>
							</div>
							<a className="users-list-name">
								Quinn Tirrell
							</a>
							<span className="users-list-date">
								2 Dec
							</span>
						</li>
					</ul>
					<ul className="users-list clearfix">
						<li>
							<div className="d-flex justify-content-center">
								<Avatar sx={{ margin: 1 }} src={"https://adminlte.io/themes/v3/dist/img/user6-128x128.jpg"}/>
							</div>
							<a className="users-list-name">
								Robert Aislynn
							</a>
							<span className="users-list-date">
								30 Nov
							</span>
						</li>
						<li>
							<div className="d-flex justify-content-center">
								<Avatar sx={{ margin: 1 }} src={"https://adminlte.io/themes/v3/dist/img/user8-128x128.jpg"}/>
							</div>
							<a className="users-list-name">
								Bob Shankles
							</a>
							<span className="users-list-date">
								30 Nov
							</span>
						</li>
						<li>
							<div className="d-flex justify-content-center">
								<Avatar sx={{ margin: 1 }} src={"https://adminlte.io/themes/v3/dist/img/user7-128x128.jpg"}/>
							</div>
							<a className="users-list-name">
								Zoey Jones
							</a>
							<span className="users-list-date">
								28 Nov
							</span>
						</li>
						<li>
							<div className="d-flex justify-content-center">
								<Avatar sx={{ margin: 1 }} src={"https://adminlte.io/themes/v3/dist/img/user2-160x160.jpg"}/>
							</div>
							<a className="users-list-name">
								Quill Ellis
							</a>
							<span className="users-list-date">
								27 Nov
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Member;