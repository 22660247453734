import Footer from "./../../Layout/Footer";
import Header from "./../../Layout/Header";
import Menu from "./../../Layout/Menu";
import CategoryRevenue from "./CategoryRevenue";
import PlayerCount from "./PlayerCount";
import CompareFood from "./CompareFood";
import { vBoolDev } from "../../Global"
import CategoryRevenueDev from "./Dev/CategoryRevenueDev";
import PlayerCountDev from "./Dev/PlayerCountDev";
import CompareFoodDev from "./Dev/CompareFoodDev";
import HeaderDev from "../../Layout/Dev/HeaderDev";

const ExecutiveHighlight = () => {
	return (
		<div>
			{
				vBoolDev == true
				?
				<HeaderDev/>
				:
				<Header/>
			}
			<Menu/>
			<div className="content-wrapper">
				<div className="container-fluid">
					<div className="content p-3">
						<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
							<div className="col-12">
								{
									vBoolDev == true
									?
									<>
										<CategoryRevenueDev/>
										{/* <PlayerCountDev/> */}
										<PlayerCount/>
										<CompareFoodDev/>
									</>
									:
									<>
										<CategoryRevenue/>
										<PlayerCount/>
										<CompareFood/>
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer/>
		</div>
	);
};

export default ExecutiveHighlight