import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import Loading from '../Revenue/LazyLoading'; 
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import format from 'date-fns/format';
import { startOfMonth, endOfMonth, endOfYear, startOfYear } from 'date-fns';
import './StyleNOP.css'

ChartJS.register(...registerables )

const NOPDetail = lazy( () => import( './NOPDetail.js' ) );

const NOPChart = ( {Period} ) => {
	const [ OpenChart, SetOpenChart ] = useState( false );
	const [ loading, Setloading ] = useState( true )
	const [ DataTemp, SetDataTemp ] = useState( [] )
	const [ DetailDate, SetDetailDate ] = useState( "" )
	
	let StartDateTemp = ""
	let EndDateTemp = ""

	if( Period == "Daily" ){
		EndDateTemp = new Date( vGlobalDateNow )
		StartDateTemp = new Date( EndDateTemp.getTime() - ( 14 * 24 * 60 * 60 * 1000 ) )
	}
	else if( Period == "Monthly" ){
		const NewDate = new Date( vGlobalDateNow );

		EndDateTemp = endOfMonth( NewDate );
		StartDateTemp = subtractMonths( startOfMonth( NewDate ), 13 );
	}
	else{
		const NewDate = new Date( vGlobalDateNow );

		EndDateTemp = endOfYear( NewDate );
		StartDateTemp = subtractYears( startOfYear( NewDate ), 5 );
	}

	const [ EndDate, SetEndDate ] = useState( EndDateTemp );
	const [ StartDate, SetStartDate ] = useState( StartDateTemp );

	function subtractMonths( date, months ) {
		date.setMonth( date.getMonth() - months );
		return date;
	}

	function subtractYears( date, years ) {
		date.setFullYear( date.getFullYear() - years );
		return date;
	}

	const GetData = async () => {
		Setloading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/NumberOfPlayer/Period";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					StartDate: {
						Value: format( StartDate, 'dd-MMM-yyyy' ),
					},
					EndDate: {
						Value: format( EndDate, 'dd-MMM-yyyy' ),
					},
					Period: {
						Value: Period,
					}
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						let i = 0
						for( i ; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}
						if( vStatus === 1 ){
							SetDataTemp( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}
		catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}
		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		Setloading( false );
	}

	let ChartData = {}
	let ChartOption = {}
	
	if( Period == "Daily" ){
		const Color = [
			'rgba(255, 99, 132, 0.5)',
			'rgba(3, 252, 227, 0.5)',
			'rgba(3, 252, 15, 0.5)',
			'rgba(252, 252, 3, 0.5)',
			'rgba(252, 136, 3, 0.5)',
			'rgba(24, 3, 252, 0.5)',
			'rgba(250, 50, 193, 0.5)'
		]
	
		ChartData = {
			labels: DataTemp.map( Value => Value.Date ),
			datasets: [
				{
					label: 'Total Player',
					data: DataTemp.map( Value => Value.TotalPlayer ),
					backgroundColor: Color,
					borderColor: Color,
					borderWidth: 1, 
					order: 1
				},
			],
		}
	
		ChartOption = {
			maintainAspectRatio: false,
			responsive : true,
			scales: {
				x: {
					beginAtZero: true,
				},
				y: {
					beginAtZero: true,
				},
			},
			plugins: {
				legend: {
					display: false,
					position: 'top',
					align: 'center',
					labels: {
						boxHeight: 10,
						boxWidth: 10,
						textAlign: 'left'
					}
				},
				tooltip: {
					callbacks: {
						label: ((tooltipItem, data) => {
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 0) {
								return DataTemp.map(Value => Value.Date).slice(0, 1);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 1) {
								return DataTemp.map(Value => Value.Date).slice(1, 2);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 2) {
								return DataTemp.map(Value => Value.Date).slice(2, 3);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 3) {
								return DataTemp.map(Value => Value.Date).slice(3, 4);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 4) {
								return DataTemp.map(Value => Value.Date).slice(4, 5);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 5) {
								return DataTemp.map(Value => Value.Date).slice(5, 6);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 6) {
								return DataTemp.map(Value => Value.Date).slice(6, 7);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 7) {
								return DataTemp.map(Value => Value.Date).slice(7, 8);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 8) {
								return DataTemp.map(Value => Value.Date).slice(8, 9);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 9) {
								return DataTemp.map(Value => Value.Date).slice(9, 10);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 10) {
								return DataTemp.map(Value => Value.Date).slice(10, 11);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 11) {
								return DataTemp.map(Value => Value.Date).slice(11, 12);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 12) {
								return DataTemp.map(Value => Value.Date).slice(12, 13);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 13) {
								return DataTemp.map(Value => Value.Date).slice(13, 14);
							}
							if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 14) {
								return DataTemp.map(Value => Value.Date).slice(14, 15);
							}
						}
						),
						title: function(tooltipItem, data) {
							return '';
						}
					}
				},
				datalabels: {
					anchor: 'center',
					align: 'center',
					display: 'true',
					clamp: true
				},
			},
			onClick: (e, activeEls) => {
				let vDataIndex = activeEls[ 0 ].index;
				SetDetailDate(e.chart.data.labels[ vDataIndex ]);
				SetOpenChart(true);
			},
			onHover: (e, ChartElement) => {
				e.native.target.style.cursor = ChartElement[0] ? 'pointer' : 'default'
			},
		}
	}
	else if( Period == "Yearly" ){
		const Color = [
			'rgba(255, 99, 132, 0.5)',
			'rgba(3, 252, 227, 0.5)',
			'rgba(3, 252, 15, 0.5)',
			'rgba(252, 252, 3, 0.5)',
			'rgba(252, 136, 3, 0.5)',
			'rgba(24, 3, 252, 0.5)',
			'rgba(250, 50, 193, 0.5)'
		]
	
		ChartData = {
			labels: DataTemp.map( Value => Value.Date ),
			datasets: [
				{
					label: 'Total Player',
					data: DataTemp.map( Value => Value.TotalPlayer ),
					backgroundColor: Color,
					borderColor: Color,
					borderWidth: 1, 
					order: 1
				},
			],
		}

		ChartOption = {
			maintainAspectRatio: false,
			responsive : true,
			scales: {
				x: {
					beginAtZero: true,
				},
				y: {
					beginAtZero: true,
				},
			},
			plugins: {
				legend: {
					display: true,
					position: 'top',
					align: 'center',
					labels: {
						boxHeight: 10,
						boxWidth: 10,
						textAlign: 'left'
					}
				},
				tooltip: {
					callbacks: {
						label: ((tooltipItem, data) => {
							return 'Total Player : ' + tooltipItem.parsed.y;
						}),
						title: function(tooltipItem, data) {
							return '';
						}
					}
				},
				datalabels: {
					anchor: 'center',
					align: 'center',
					display: 'true',
					clamp: true
				},
			},
			onClick: (e, activeEls) => {
				let vDataIndex = activeEls[ 0 ].index;
				SetDetailDate(e.chart.data.labels[ vDataIndex ]);
				SetOpenChart(true);
			},
			onHover: (e, ChartElement) => {
				e.native.target.style.cursor = ChartElement[0] ? 'pointer' : 'default'
			},
		}
	}
	else if( Period == "Monthly" ){
		ChartData = {
			labels: DataTemp.map( Value => Value.Date ),
			datasets: [
				{
					label: 'Total Player',
					data: DataTemp.map( Value => Value.TotalPlayer ),
					backgroundColor: DataTemp.map( Value => Value.Color ),
					borderColor: DataTemp.map( Value => Value.Color ),
					borderWidth: 1
				},
				{
					label: 'Average Yearly',
					data: DataTemp.map( Value => Value.TotalAvg ),
					backgroundColor: 'rgb(104, 182, 239)',
					borderColor: 'rgb(104, 182, 239)',
					type: 'line',
					datalabels: {
						labels: {
							title: null
						}
					}
				},
			],
		};

		ChartOption = {
			maintainAspectRatio: false,
			responsive : true,
			scales: {
				x: {
					beginAtZero: true,
				},
				y: {
					beginAtZero: true,
				},
			},
			plugins: {
				legend: {
					display: true,
					position: 'top',
					align: 'center',
					labels: {
						boxHeight: 10,
						boxWidth: 10,
						textAlign: 'left'
					}
				},
				tooltip: {
					callbacks: {
						label: ((tooltipItem, data) => {
							return 'Total Player : ' + parseInt(tooltipItem.parsed.y);
						}),
						title: function(tooltipItem, data) {
							return '';
						}
					}
				},
				datalabels: {
					anchor: 'center',
					align: 'center',
					display: 'true',
					clamp: true
				},
			},
			onClick: (e, activeEls) => {
				let vDataIndex = activeEls[ 0 ].index;
				SetDetailDate(e.chart.data.labels[ vDataIndex ]);
				SetOpenChart(true)
			},
			onHover: (e, ChartElement) => {
				e.native.target.style.cursor = ChartElement[0] ? 'pointer' : 'default'
			},
		}
	}
	
	const renderMonthContent = ( month, shortMonth, longMonth ) => {
		return <span>{shortMonth}</span>;
	};

	const renderYearContent = ( year ) => {
		return <span>{year}</span>;
	};

	useEffect( () => {
		GetData();
	}, [] );

	return (
		<>
			<div className="col-12 col-lg-6">
				<div className="card bg-gradient-light">
					<div className="card-header">
						<h3 className="card-title text-success font-weight-bold">
							NOP CHART
						</h3>
						<div className="card-tools">
							<ul className="nav nav-pills ml-auto">
								<li className="nav-item text-info font-weight-bold">
									{Period.toUpperCase()}
								</li>
							</ul>
						</div>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-6 col-lg-4">
								<div className="form-group">
									<label>
										Start Date
									</label>
									{
										Period == "Daily" 
											?
											<DatePicker
												dateFormat="dd-MMM-yyyy"
												selected={ StartDate }
												onChange={e => SetStartDate(e)}
												className="form-control"
												value={ StartDate }
											/>
											:
										Period == "Monthly" 
											?
											<DatePicker
												dateFormat="MMM-yyyy"
												renderMonthContent={renderMonthContent}
												showMonthYearPicker
												selected={StartDate}
												onChange={e => SetStartDate(startOfMonth(e))}
												className="form-control"
												value={StartDate}
											/>
											:
											<DatePicker
												dateFormat="yyyy"
												renderYearContent={renderYearContent}
												showYearPicker
												selected={StartDate}
												onChange={e => SetStartDate(startOfYear(e))}
												className="form-control"
												value={StartDate}
											/>
									}
								</div>
							</div>
							<div className="col-6 col-lg-4">
								<div className="form-group">
									<label>
										End Date
									</label>
									{
										Period == "Daily" 
											?
											<DatePicker
												dateFormat="dd-MMM-yyyy"
												selected={ EndDate }
												onChange={e => SetEndDate(e)}
												className="form-control"
												value={ EndDate }
											/>
											:
										Period == "Monthly" 
											?
											<DatePicker
												dateFormat="MMM-yyyy"
												renderMonthContent={renderMonthContent}
												showMonthYearPicker
												selected={EndDate}
												onChange={e => SetEndDate(endOfMonth(e))}
												className="form-control"
												value={EndDate}
											/>
											:
											<DatePicker
												dateFormat="yyyy"
												renderYearContent={renderYearContent}
												showYearPicker
												selected={EndDate}
												onChange={e => SetEndDate(endOfYear(e))}
												className="form-control"
												value={EndDate}
											/>
									}
								</div>
							</div>
							<div className="col-12 col-lg-4">
								<button
									onClick={GetData}
									type="submit"
									className="btn btn-primary btn-srh-nop"
								>
									<i className="fas fa-search"/>
									&nbsp;
									{loading ?
											"Please wait..."
										:
											"Search"
									}
								</button>
							</div>
						</div>
						<div className="tab-content p-0">
							{
								loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"/>
								</div>
								:
								<div className="Chart-Container">
									<Bar
										data={ChartData}
										plugins={[ChartDataLabels]}
										options={ChartOption}
										height={null}
										width={null}
									/>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
			{OpenChart ? (
				<Suspense fallback={<Loading />}>
					<NOPDetail
						Period={Period}
						DetailDate={DetailDate}
					/>
				</Suspense>
				) : ""
			}
		</>
	)
}

export default NOPChart