import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import toastr from 'toastr';
import './StyleReservation.css'
import { startOfMonth, format } from 'date-fns';

ChartJS.register(...registerables )

const ChartCCHMonthly = () => {
	const [TotalQtyMonthly, SetTotalQtyMonthly] = useState([]);
	const [loading, setLoading] = useState(false)
	let StartDate = format( startOfMonth( new Date( vGlobalDateNow ) ), 'dd MMM yyyy' )
	let EndDate = format( new Date( vGlobalDateNow ), 'dd MMM yyyy' )

	const GetRoomReservationData = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Reservation/RoomMonthly";
			const vData = {
				"Action": {
					"Value": "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;
						
						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							SetTotalQtyMonthly( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}

		setLoading( false );
	}

	useEffect(() => {
		GetRoomReservationData()
	}, [])

	const DataCCHA = TotalQtyMonthly.map(row => row.CCHA)
	const DataCCHB = TotalQtyMonthly.map(row => row.CCHB)
	const DataCCHC = TotalQtyMonthly.map(row => row.CCHC)
	const DataCCHD = TotalQtyMonthly.map(row => row.CCHD)
	const DataCCHE = TotalQtyMonthly.map(row => row.CCHE)
	const DataCCHM = TotalQtyMonthly.map(row => row.CCHM)
	const DataCCHO = TotalQtyMonthly.map(row => row.CCHO)
	const DataCCHQ = TotalQtyMonthly.map(row => row.CCHQ)
	const DataAll = DataCCHA.concat(DataCCHB,DataCCHC,DataCCHD,DataCCHE,DataCCHM,DataCCHO,DataCCHQ);

	const Color = [
		'rgb(255, 99, 132)',
		'rgb(3, 252, 227)',
		'rgb(3, 252, 15)',
		'rgb(252, 252, 3)',
		'rgb(252, 136, 3)',
		'rgb(171, 196, 255)',
		'rgb(255, 238, 147)',
		'rgb(128, 155, 206)'
	]

	let data = {
		labels: ['CCH A', 'CCH B', 'CCH C', 'CCH D', 'CCH E', 'CCH M', 'CCH O', 'CCH Q']
		, datasets: [{
			data: DataAll
			, backgroundColor: Color
			, borderColor: Color
			, borderWidth: 1 
		}]
	}

	let option = {
		maintainAspectRatio: false,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true
			},
			y: {
				beginAtZero: true
			}
		},
		plugins: {
			legend: {
				display: false,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 5,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			tooltip: {
				callbacks: {
					label: ((tooltipItem, data) => {
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 0) {
							return 'CCH A';
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 1) {
							return 'CCH B';
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 2) {
							return 'CCH C';
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 3) {
							return 'CCH D';
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 4) {
							return 'CCH E';
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 5) {
							return 'CCH M';
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 6) {
							return 'CCH O';
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 7) {
							return 'CCH Q';
						}
					}
					),
					title: function(tooltipItem, data) {
						return '';
					}
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: 'auto',
				font: {
					size: 15,
				}
			}
		},        
	}

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info font-weight-bold">
						CCH USAGE CHART MONTH TO DATE
					</li>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info">
						(In Qty)
					</li>
				</ul>
				<div className="text-info">
					{StartDate} - {EndDate}
				</div>
			</div>
			<div className="card-body">
				<div className="tab-content p-0 ">
					{loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<div className="Chart-Container">
							<Bar
								data={data}
								plugins={[ChartDataLabels]}
								options={option}
								height={null}
								width={null}
							/>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default ChartCCHMonthly