import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { vAPIURL, vBoolDev, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';

const PlayerCount = () => {

	const [DataPlayerCount, SetDataPlayerCount] = useState([]);
	const [Loading, setLoading] = useState(false);
	const [EndDate, SetEndDate ] = useState( new Date( vGlobalDateNow ) );
	const [StartDate, SetStartDate ] = useState( new Date( EndDate.getTime() - ( 7 * 24 * 60 * 60 * 1000 )  ) );

	const GetPlayerCount = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try {
			const vURL = vAPIURL + "/Golf/Executive/PlayerCount";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					StartDate: {
						Value: format( StartDate, 'dd-MMM-yyyy' ),
					},
					EndDate: {
						Value: format( EndDate, 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then((vResponse) => {
					vStatus = vResponse.data.Status;

					for (var i = 0; i < vResponse.data.Message.length; i++) {
						vMessage.push(vResponse.data.Message[i]);
					}

					if (vStatus === 1) {
						if ( vBoolDev == true ){
							const newData = [...vResponse.data.Output.Result];
							
							const temp = newData[0].Type;
							newData[0].Type = newData[1].Type;
							newData[1].Type = temp;
					
							SetDataPlayerCount(newData)
						}
						else{
							SetDataPlayerCount(vResponse.data.Output.Result);
						}
					}
				})
				.catch((vError) => {
					vStatus = 2;
					vMessage.push("Error");
				});
		} catch (vError) {
			vStatus = 2;
			vMessage.push("Error");
		}

		if (vMessage.length > 0) {
			if (vStatus === 1) {
				//toastr.success( vMessage.join( "</br>" ) );
			} else {
				toastr.error(vMessage.join("</br>"));
			}
		}
		setLoading(false);
	};

	const BorderLinearProgressGreen = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[200],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: '#00b050',
		},
	}));

	useEffect(() => {
		GetPlayerCount();
	}, []);

	const Boldtify = (Index, ArrayLenght) => {
		const StyleTemp = {
			fontWeight: Index === ArrayLenght - 1 ? 'bold' : 'normal'
		}
		return StyleTemp
	}

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<h3 className="card-title text-success font-weight-bold">
					FREQUENT PLAYERS
				</h3>
				<div className="card-tools">
					<ul className="nav nav-pills ml-auto">
						<li className="nav-item text-info font-weight-bold">
						</li>
					</ul>
				</div>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col-6 col-lg-2">
						<div className="form-group">
							<label>
								START DATE
							</label>
							<DatePicker
								dateFormat="dd-MMM-yyyy"
								selected={ StartDate }
								onChange={e => SetStartDate(e)}
								className="form-control"
								value={ StartDate }
							/>
						</div>
					</div>
					<div className="col-6 col-lg-2">
						<div className="form-group">
							<label>
								END DATE
							</label>
							<DatePicker
								dateFormat="dd-MMM-yyyy"
								selected={ EndDate }
								onChange={e => SetEndDate(e)}
								className="form-control"
								value={ EndDate }
							/>
						</div>
					</div>
					<div className="col-12 col-lg-2">
						<button
							onClick={GetPlayerCount}
							type="submit"
							className="btn btn-primary btn-srh-responsive"
						>
							<i className="fas fa-search"/>
							&nbsp;
							{Loading ?
									"Please wait..."
								:
									"Search"
							}
						</button>
					</div>
				</div>
				*Note Must Field In With Period Interval
				<div className="tab-content p-0">
					{Loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<div className="container-table shadow">
							<div className="detail-table">
								<table id="table-freqplayer">
									<tbody>
										{DataPlayerCount.map((Value, Index) => (
											<tr>
												<td className="column-1" style={Boldtify( Index, DataPlayerCount.length )}>
													{Value.Type}
												</td>
												<td className="column-3">
													<BorderLinearProgressGreen variant="determinate" value={Value.Percentage}/>
												</td>
												<td className="column-4" style={Boldtify( Index, DataPlayerCount.length )}>
													{Value.Percentage} %
												</td>
												<td className="column-5" style={Boldtify( Index, DataPlayerCount.length )}>
													{Value.PlayerCount}
												</td>
											</tr>
											))
										}
									</tbody>
								</table>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default PlayerCount