import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';
import TablePagination from '@mui/material/TablePagination';
import { startOfWeek,endOfWeek, getISOWeek } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

const CompareFood = () => {

	const NowDate = new Date( vGlobalDateNow );
	let LastWeekDate = new Date( vGlobalDateNow );
	LastWeekDate = LastWeekDate.setDate(LastWeekDate.getDate() - 8);
	const [DataCompare, SetDataCompare] = useState([]);
	const [Loading, SetLoading] = useState(false);
	const [WeekStartDate1, SetWeekStartDate1] = useState(startOfWeek(LastWeekDate, { weekStartsOn: 1 }));
	const [WeekEndDate1, SetWeekEndDate1] = useState(endOfWeek(LastWeekDate, { weekStartsOn: 1 }));
	const [WeekStartDate2, SetWeekStartDate2] = useState(startOfWeek(NowDate, { weekStartsOn: 1 }));
	const [WeekEndDate2, SetWeekEndDate2] =  useState(endOfWeek(NowDate, { weekStartsOn: 1 }));
	const [TypeMenu, SetTypeMenu] = useState("Food");
	const [Week1, SetWeek1] = useState(getISOWeek(WeekStartDate1));
	const [Week2, SetWeek2] = useState(getISOWeek(WeekStartDate2));

	function RefreshData() {
		GetCompareData()
		if ( getISOWeek(WeekStartDate1) == getISOWeek(WeekEndDate1) ){
			SetWeek1(getISOWeek(WeekStartDate1))
		}
		else{
			SetWeek1(getISOWeek(WeekStartDate1) + "-" + getISOWeek(WeekEndDate1))
		}

		if ( getISOWeek(WeekStartDate2) == getISOWeek(WeekEndDate2) ){
			SetWeek2(getISOWeek(WeekStartDate2))
		}
		else{
			SetWeek2(getISOWeek(WeekStartDate2) + "-" + getISOWeek(WeekEndDate2))
		}
	}

	const GetCompareData = async () => {
		SetLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try {
			const vURL = vAPIURL + "/Golf/Executive/Compare";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					StartDate1: {
						Value: format( WeekStartDate1, 'dd-MMM-yyyy' ),
					},
					EndDate1: {
						Value: format( WeekEndDate1, 'dd-MMM-yyyy' ),
					},
					StartDate2: {
						Value: format( WeekStartDate2, 'dd-MMM-yyyy' ),
					},
					EndDate2: {
						Value: format( WeekEndDate2, 'dd-MMM-yyyy' ),
					},
					TypeMenu: {
						Value: TypeMenu,
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then((vResponse) => {
					vStatus = vResponse.data.Status;

					for (var i = 0; i < vResponse.data.Message.length; i++) {
						vMessage.push(vResponse.data.Message[i]);
					}

					if (vStatus === 1) {
						SetDataCompare(vResponse.data.Output.Result);
					}
				})
				.catch((vError) => {
					vStatus = 2;
					vMessage.push("Error");
				});
		} catch (vError) {
			vStatus = 2;
			vMessage.push("Error");
		}

		if (vMessage.length > 0) {
			if (vStatus === 1) {
				//toastr.success( vMessage.join( "</br>" ) );
			} else {
				toastr.error(vMessage.join("</br>"));
			}
		}
		SetLoading(false);
	};

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	
	const SetterWeek1 = (e) => {
		SetWeekStartDate1(startOfWeek(e, { weekStartsOn: 1 }))
		SetWeekEndDate1(endOfWeek(e, { weekStartsOn: 1 }))
	}
	
	const SetterWeek2 = (e) => {
		SetWeekStartDate2(startOfWeek(e, { weekStartsOn: 1 }))
		SetWeekEndDate2(endOfWeek(e, { weekStartsOn: 1 }))
	}
	
	let SumWeek1Qty = 0;
	let SumWeek1Percentage = 0;
	let SumWeek2Qty = 0;
	let SumWeek2Percentage = 0;
	let TotalDifference = 0;

	const DataCompareTemp = (
		rowsPerPage > 0 
		? DataCompare.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		: DataCompare
	)

	DataCompareTemp.map(Value => {
		SumWeek1Percentage += parseFloat(Value.PercentageWeek1)
		SumWeek2Percentage += parseFloat(Value.PercentageWeek2)
	})

	DataCompare.map(Value => {
		SumWeek1Qty += parseInt(Value.QtyWeek1)
		SumWeek2Qty += parseInt(Value.QtyWeek2)
	})

	TotalDifference = parseFloat( ( (SumWeek2Qty-SumWeek1Qty) * 100 ) / SumWeek2Qty ).toFixed(1)
	
	useEffect(() => {
		GetCompareData();
	}, []);

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<h3 className="card-title text-success font-weight-bold">
					COMPARE TOP SELLING FOOD
				</h3>
				<div className="card-tools">
					<ul className="nav nav-pills ml-auto">
						<li className="nav-item text-info font-weight-bold">
						</li>
					</ul>
				</div>
			</div>
			{Loading ?
				<div className="d-flex justify-content-center">
					<i className="fas fa-3x fa-sync-alt fa-spin"></i>
				</div>
				:
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead sx={{ minHeight: 10 }}>
							<TableRow>
								<TableHead>
									<TableCell 
										sx={{
											width: 436
										}}
										colSpan={2}
									>
										<div className="dropdown show">
											<a className="btn btn-primary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width:'150px' }}>
												{TypeMenu}
											</a>
											<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
												<button 
													className="dropdown-item" 
													type="button"
													onClick={() => {
														SetTypeMenu("Food")
													}}
												>
													Food
												</button>
												<button
													className="dropdown-item" 
													type="button"
													onClick={() => {
														SetTypeMenu("Beverage")
													}}
												>
													Beverage
												</button>
												<button 
													className="dropdown-item" 
													type="button"
													onClick={() => {
														SetTypeMenu("Cigarette")
													}}
												>
													Cigarette
												</button>
												<button 
													className="dropdown-item" 
													type="button"
													onClick={() => {
														SetTypeMenu("CoolBox")
													}}
												>
													CoolBox
												</button>
											</div>
										</div>
									</TableCell>
									<TableCell 
										sx={{ 
											width: 300,
											fontWeight: 'bold'
										}} 
										colSpan={2}
									>
										<div className="row">
											<div className="col-12">
												<div className="form-group">
													<label>
														WEEK 1
													</label>
													<DatePicker
														dateFormat="I-R"
														selected={ WeekStartDate1 }
														onChange={e => SetterWeek1(e)}
														className="form-control"
														value={ WeekStartDate1 }
														showWeekNumbers
														locale={enGB}
														startDay={1}
													/>
												</div>
											</div>
										</div>
									</TableCell>
									<TableCell 
										sx={{ 
											width: 300,
											fontWeight: 'bold'
										}}
										colSpan={2}
									>
										<div className="row">
											<div className="col-12">
												<div className="form-group">
													<label>
														WEEK 2
													</label>
													<DatePicker
														dateFormat="I-R"
														selected={ WeekStartDate2 }
														onChange={e => SetterWeek2(e)}
														className="form-control"
														value={ WeekStartDate2 }
														showWeekNumbers
														locale={enGB}
														startDay={1}
													/>
												</div>
											</div>
										</div>
									</TableCell>
									<TableCell 
										sx={{ 
											width: 120,
											fontWeight: 'bold'
										}}
									>
										<button
											onClick={RefreshData}
											type="submit"
											className="btn btn-primary"
											// style={{ marginTop:"31px" }}
										>
											<i className="fas fa-search"/>
											&nbsp;
											{Loading ?
													"Please wait..."
												:
													"Search"
											}
										</button>
									</TableCell>
								</TableHead>
								<TableHead>
									<TableCell 
										sx={{
											width: 436
										}}
										colSpan={2}
									>
									</TableCell>
									<TableCell 
										sx={{ 
											width: 300,
											fontWeight: 'bold'
										}} 
										colSpan={2}
									>
										Week : {Week1}
									</TableCell>
									<TableCell 
										sx={{ 
											width: 300,
											fontWeight: 'bold'
										}}
										colSpan={2}
									>
										Week : {Week2}
									</TableCell>
									<TableCell 
										sx={{ 
											width: 120,
											fontWeight: 'bold'
										}}
									>
										Difference
									</TableCell>
								</TableHead>
								{DataCompare
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((Value, Index) => (
									<TableHead>
										<TableCell 
											sx={{
												width: 36
											}}
										>
											{Value.No}
										</TableCell>
										<TableCell 
											sx={{
												width: 400 
											}}
										>
											{Value.MenuName}
										</TableCell>
										<TableCell 
											sx={{ 
												width: 140
											}} 
										>
											{Value.QtyWeek1}
										</TableCell>
										<TableCell 
											sx={{ 
												width: 140
											}}
										>
											{Value.PercentageWeek1} %
										</TableCell>
										<TableCell 
											sx={{ 
												width: 140
											}} 
										>
											{Value.QtyWeek2}
										</TableCell>
										<TableCell 
											sx={{ 
												width: 140
											}}
										>
											{Value.PercentageWeek2} %
										</TableCell>
										{
											Value.Difference > 0 
											?
												<TableCell 
													sx={{ 
														width: 140
														, color: "green"
													}}
												>
													<FontAwesomeIcon icon={faCaretUp} />
													&nbsp;{Value.Difference} %
												</TableCell>
											:
												<TableCell 
													sx={{ 
														width: 140
														, color: "red"
													}}
												>
													<FontAwesomeIcon icon={faCaretDown} />
													&nbsp;{Value.Difference} %
												</TableCell>
										}
									</TableHead>
								))}
								<TableHead>
									<TableCell
										sx={{
											width: 36
										}}
									>
									</TableCell>
									<TableCell 
										sx={{
											width: 400 
										}}
									>
										Total
									</TableCell>
									<TableCell 
										sx={{ 
											width: 140
										}} 
									>
										{SumWeek1Qty}
									</TableCell>
									<TableCell 
										sx={{ 
											width: 140
										}}
									>
										{parseFloat(SumWeek1Percentage).toFixed(1)} %
									</TableCell>
									<TableCell 
										sx={{ 
											width: 140
										}} 
									>
										{SumWeek2Qty}
									</TableCell>
									<TableCell 
										sx={{ 
											width: 140
										}}
									>
										{parseFloat(SumWeek2Percentage).toFixed(1)} %
									</TableCell>
									{
										TotalDifference > 0 
										?
											<TableCell 
												sx={{ 
													width: 140
													, color: "green"
												}}
											>
												<FontAwesomeIcon icon={faCaretUp} />
												&nbsp;{TotalDifference} %
											</TableCell>
										:
											<TableCell 
												sx={{ 
													width: 140
													, color: "red"
												}}
											>
												<FontAwesomeIcon icon={faCaretDown} />
												&nbsp;{TotalDifference} %
											</TableCell>
									}
								</TableHead>
							</TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, 100]}
								component="div"
								count={DataCompare.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</TableHead>
					</Table>
				</TableContainer>
			}
		</div>
	);
}

export default CompareFood