import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Menu from "../../Layout/Menu";
import React from "react";
import NOPTable from "./NumberOfPlayerTable";
import WeeklyNOPChart from "./WeeklyNOPChart";
import NOPChart from "./NOPChart";
import { vBoolDev } from "../../Global"
import NOPChartDev from "./Dev/NOPChartDev";
import HeaderDev from "../../Layout/Dev/HeaderDev";
import NOPTableDev from "./Dev/NOPTable";

const NumberOfPlayer = () => {
	return (
		<div>
			{
				vBoolDev == true
				?
				<HeaderDev/>
				:
				<Header/>
			}
			<Menu/>
			<div className="content-wrapper">
				<div className="container-fluid">
					<div className="content p-3">
						<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
							<div className="col-12">
								{
									vBoolDev == true
									?
									<>
										<div className="row">
											<NOPChart Period={"Daily"}/>
										</div>
										<WeeklyNOPChart/>
										<div className="row">
											<NOPChartDev Period={"Monthly"}/>
											<NOPChartDev Period={"Yearly"}/>
										</div>
										<NOPTableDev/>
									</>
									:
									<>
										<div className="row">
											<NOPChart Period={"Daily"}/>
										</div>
										<WeeklyNOPChart/>
										<div className="row">
											<NOPChart Period={"Monthly"}/>
											<NOPChart Period={"Yearly"}/>
										</div>
										<NOPTable/>
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default NumberOfPlayer