import Footer from "./../../Layout/Footer";
import Header from "./../../Layout/Header";
import Menu from "./../../Layout/Menu";
import WaitersRank from "./WaitersRank";
import TopItemCategoryChart from "./WeeklyTopCategory";
import CancelItemTableService from "./CancelItemTableService";
import WeeklyTotalSellout from "./WeeklyTotalSelloutChart";
import TopSellingTableFnB from "./TopSellingItemTable1";
import TopSellingTableCnCB from "./TopSellingItemTable2";
import BeverageOverUnderDuration from "./BeverageOverUnderDuration";
import HeaderDev from "../../Layout/Dev/HeaderDev";
import { vBoolDev } from "../../Global";

const FnBServices = () => {
	return (
		<div>
			{
				vBoolDev == true
				?
				<HeaderDev/>
				:
				<Header/>
			}
			<Menu/>
			<div className="content-wrapper">
				<div className="container-fluid">
					<div className="content p-3">
						<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
							<div className="col-12">
								<WeeklyTotalSellout />
								<CancelItemTableService />
								<TopItemCategoryChart />
								<WaitersRank />
								<TopSellingTableFnB/>
								<TopSellingTableCnCB/>
								<BeverageOverUnderDuration/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer/>
		</div>
	);
};

export default FnBServices