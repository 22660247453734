import 'toastr/build/toastr.min.css';
import axios from "axios";

import Golf_BG from './../Assets/Golf_BG.jpg';
import Travel_BG from './../Assets/Travel_BG.png';
import Askara_BG from './../Assets/Askara_BG.png';

import Golf_Logo from './../Assets/Golf_Logo.png';
import Travel_Logo from './../Assets/Travel_Logo.jpg';
import Askara_Logo from './../Assets/Askara_Logo.png';

import React, { useState } from 'react';
import toastr from "toastr";
import { Box } from "@mui/system";
import { Button, TextField, Typography, InputLabel, InputAdornment, FormControl, OutlinedInput, IconButton } from "@mui/material";
import { Link } from 'react-router-dom';
import { vAPIURL, vBoolGolf, vBoolTravel } from './../Global';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const SignIn = () => {
	const [ vLoading, SetLoading ] = useState( false );
	const [ vUsername, SetUsername ] = useState( "" );
	const [ vPassword, SetPassword ] = useState( "" );
	const [ showPassword, setShowPassword ] = useState(false);

	let vBG = Askara_BG;
	let vLogo = Askara_Logo;
	let vTitle = "ASKARA";

	if( vBoolGolf && vBoolTravel ){
	}
	else if( vBoolGolf ){
		vBG = Golf_BG;
		vLogo = Golf_Logo;
		vTitle = "GM360";
	}
	else if( vBoolTravel ){
		vBG = Travel_BG;
		vLogo = Travel_Logo;
		vTitle = "ITM";
	}

	const FLogin = async () => {
		SetLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Sign/In";
			const vData = {
				Action: {
					Value: "In",
				},
				Input: {
					Username: {
						Value: vUsername,
					},
					Password: {
						Value: vPassword,
					},
					OTP: {
						Value: 1,
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
				},
			};

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							localStorage.setItem( "Authentication", vResponse.data.Output.Authentication );
							localStorage.setItem( "Username", vUsername );

							if( vResponse.data.Output.OTP == 1 ){
								window.location.replace("/Sign/OTP");
							}
							else{
								window.location.replace("/");
							}
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Server Problem" );
					}
				)
			;
		}
		catch( vError ){
			vStatus = 2;
			vMessage.push( "Server Problem" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}

		SetLoading( false );
	};
	
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
	  event.preventDefault();
	};

	return (
		<div
			style={{ 
				backgroundImage: `url(${vBG})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				height: '100vh',
				padding: '10px'
			}}
		>
			<div align="center">
				<Box>
					<div className="login-box">
						<div className="card card-outline card-primary" style={{ marginTop: 90}}>
							<div className="card-header text-center">
								<div className="row ">
									<div className="col-3">
										<img src={vLogo} className="brand-image img-circle elevation-3" style={{ height:60, opacity: '.8' }} />
									</div>
									<div className="col-9">
										<Typography sx={{mt: 2}} variant="h5">
											{vTitle} Dashboard
										</Typography>
									</div>
								</div>
							</div>
							<div className="card-body">
								<h6>
									Sign in to start your session
								</h6>
								<TextField
									fullWidth
									id="Username"
									label="Username"
									margin="normal"
									name="Username"
									onChange={e => SetUsername(e.target.value)}
									variant="outlined"
								/>
								<FormControl 
									fullWidth
									margin="normal"
									onChange={e => SetPassword(e.target.value)}
								>
									<InputLabel htmlFor="outlined-adornment-password">
										Password
									</InputLabel>
									<OutlinedInput
										id="outlined-adornment-password"
										type={showPassword ? 'text' : 'password'}
										endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
										}
										label="Password"
									/>
								</FormControl>
								{/* <TextField
									fullWidth
									id="password"
									label="Password"
									margin="normal"
									name="password"
									onChange={e => SetPassword(e.target.value)}
									type="password"
									variant="outlined"
								/> */}
								{
									vLoading?
										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
										>
											<center>
												<i className="fas fa-1x fa-sync-alt fa-spin">
												</i>
											</center>
										</Button>
									:
										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											onClick={FLogin}
										>
											Login
										</Button>
								}
								<Link
									to="/Password/Forgot/Request"
								>
									Forgot Password
								</Link>
							</div>
						</div>
					</div>
				</Box>
			</div>
		</div>
	)
}

export default SignIn