import Footer from "../../Layout/Footer"
import Header from "../../Layout/Header"
import Menu from "../../Layout/Menu"
import { vBoolGolf, vBoolDev } from "../../Global"
import Weather from "./Weather";
import BookingVsCheckin from "./BookingVsCheckin";
import ViewPoint1 from "./ViewPoint1";
import Member from "./Member";
import ViewPoint2 from "./ViewPoint2";
import ReservationReliabilty from "./ReservationReliability";
import FoodVsBeverage from "./FoodVsBeverage";
import MemberVsGuest from "./MemberVsGuest";
import WeatherDev from "./Dev/WeatherDev";
import ViewPointDev from "./Dev/ViewPointDev";
import FoodVsBeverageDev from "./Dev/FoodVsBeverageDev";
import BookingVsCheckinDev from "./Dev/BookingVsCheckinDev";
import MemberVsGuestDev from "./Dev/MemberVsGuestDev";
import HeaderDev from "../../Layout/Dev/HeaderDev";

const MainPage = () => {
	const CheckScreenWidth = window.innerWidth >= 1200;

	return (
		<div>
			{
				vBoolDev == true
				?
				<HeaderDev/>
				:
				<Header/>
			}
			<Menu/>
			{
				vBoolGolf == true
				?
				<div className="content-wrapper">
					<div className="container-fluid bg-white rounded">
						<div className="content p-3">
							<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
								<div className="col-12">
									{
										vBoolDev == true
										?
										<div className="row">
											<WeatherDev/>
											<ViewPointDev/>
											<FoodVsBeverageDev/>
											<BookingVsCheckinDev/>
											<MemberVsGuestDev/>
										</div>
										:
										<div className="row">
											<Weather/>
											{
												CheckScreenWidth ?
												<>
													<ViewPoint1/>
													<Member/>
													<ViewPoint2/>
												</>
												:
												<>
													<ViewPoint1/>
													<ViewPoint2/>
													<Member/>
												</>
											}
											<FoodVsBeverage/>
											<BookingVsCheckin/>
											<MemberVsGuest/>
											<ReservationReliabilty/>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				:
				null
			}
			<Footer />
		</div>
	)
}

export default MainPage