import Footer from "./../../Layout/Footer";
import Header from "./../../Layout/Header";
import Menu from "./../../Layout/Menu";
import CaddyGroupAttendance  from "./CaddyGroupAttendance";
import LastWeekCaddyAttendance from "./LastWeekCaddyAttendanceChart";
import ThisWeekCaddyAttendance from "./ThisWeekCaddyAttendanceChart";
import CaddyMasterTable from "./CaddyMasterTable";
import BuggyMasterTable from "./BuggyMasterTable";
import HeaderDev from "../../Layout/Dev/HeaderDev";
import { vBoolDev } from "../../Global";
import BuggyMasterTableDev from "./Dev/BuggyMasterTableDev";

const GolfOperations = () => {
	  
	return (
		<div>
			{
				vBoolDev == true
				?
				<HeaderDev/>
				:
				<Header/>
			}
			<Menu/>
			<div className="content-wrapper">
				<div className="container-fluid">
					<div className="content p-3">
						<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
							<div className="col-12">
								<div className="row">
									<LastWeekCaddyAttendance/>
									<ThisWeekCaddyAttendance/>
								</div>
								<CaddyMasterTable/>
								{
									vBoolDev == true
									?
									<BuggyMasterTableDev/>
									:
									<BuggyMasterTable/>
								}
								<CaddyGroupAttendance/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer/>
		</div>
	);
};

export default GolfOperations;