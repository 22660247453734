import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, registerables  } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import { startOfWeek, format } from 'date-fns';
import './StyleFnBService.css'

ChartJS.register(...registerables )

const WeeklyTopCategory = () => {
	const [TopItemCategory, SetTopItemCategory] = useState([]);
	const [loading, setLoading] = useState(false)
	let StartDate = format( startOfWeek( new Date( vGlobalDateNow ), { weekStartsOn: 1 } ), 'dd MMM' )
	let EndDate = format( new Date( vGlobalDateNow ), 'dd MMM' )

	const GetTopItemTable = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/FnB/Category";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							SetTopItemCategory( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading(false);

	}

	useEffect(() => {
		GetTopItemTable()
	}, [])

	const grouped = Object.values(TopItemCategory.reduce((Sorted, DataRows) => {
		Sorted[DataRows.Menu] = [...(Sorted[DataRows.Menu] || []), DataRows];
		return Sorted;
	}, {}))

	let Data1 = []

	grouped.forEach(element => {
		const Data1Temp = []
		let labelTemp = ""
		let Color = ""
		let BorderChart = ""
		let OrderChart = ""
		element.forEach(Group => {
			labelTemp = (Group.Menu);
			Data1Temp.push(Group.Qty);
		})

		element.forEach( Group => {
			if ( Group.Menu  === "FOOD" ){
				Color = 'rgb(255, 99, 132)'
				BorderChart  = 'rgb(255, 99, 132)'
				OrderChart = 0
				}
			else if ( Group.Menu  === "BEVERAGE" ){
				Color = 'rgb(54, 162, 235)'
				BorderChart  = 'rgb(54, 162, 235)'
				OrderChart = 1
			}
			else if ( Group.Menu  === "CIGARETTE" ){
				Color = 'rgb(252, 136, 3)'
				BorderChart  = 'rgb(252, 136, 3)'
				OrderChart = 2
			}
			else if ( Group.Menu  === "CORKAGE" ){
				Color = 'rgb(50, 168, 82)'
				BorderChart  = 'rgb(50, 168, 82)'
				OrderChart = 3
			}
			else {
			Color = 'rgb(235, 123, 12)'
			BorderChart  = 'rgb(235, 123, 12)'
			OrderChart = 4
			}
		})

		Data1.push(
			{
				label: labelTemp
				, data: Data1Temp
				, backgroundColor: Color
				, borderColor: BorderChart
				, order: OrderChart
				, borderWidth: 1 
			}
		)
	})

	
	let data = {
		labels: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
		datasets: Data1
	}

	let option = {
		maintainAspectRatio: false,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true,
				stacked: true
			},
			y: {
				beginAtZero: true,
				stacked: true
			}
		},
		plugins: {
			legend: {
				display: true,
				position: 'bottom',
				align: 'center',
				labels: {
					boxHeight: 10,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			tooltip: {
				callbacks: {
					title: function(tooltipItem, data) {
						return '';
					}
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: 'true'
			}
		}
	}

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info font-weight-bold">
						WEEKLY FBS SALES BY CATEGORY CHART
					</li>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info">
						(In Qty)
					</li>
				</ul>
				<div className="text-info">
					{StartDate} - {EndDate}
				</div>
			</div>
			<div className="card-body">
				<div className="tab-content p-0 ">
					<div className="row">
						<div className="col-6">
						</div>
					</div>
					{
						loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<div className="Chart-Container">
							<Bar
								data={data}
								plugins={[ChartDataLabels]}
								options={option}
								height={null}
								width={null}
							/>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default WeeklyTopCategory