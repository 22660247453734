import Footer from "./../../Layout/Footer";
import Header from "./../../Layout/Header";
import Menu from "./../../Layout/Menu";
import ChartReservation from "./ChartReservation";
import TableContributionER from "./TableContributionER";
import TableRoomYearly from "./TableRoomYearly";
import ChartRoomMonthly from "./ChartRoomMonthly";
import ChartCCHMonthly from "./ChartCCHMonthly";
import TableCCHYearly from "./TableCCHYearly";
import { vBoolDev } from "../../Global";
import BookingVsCheckin from "./BookingVsCheckin";
import HeaderDev from "../../Layout/Dev/HeaderDev";

const Reservation = () => {
	return (
		<div>
			{
				vBoolDev == true
				?
				<HeaderDev/>
				:
				<Header/>
			}
			<Menu/>
			<div className="content-wrapper">
				<div className="container-fluid">
					<div className="content p-3">
						<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
							<div className="col-12">
								{
									vBoolDev ?
									null
									:
									<div className="row">
										<BookingVsCheckin Period={"Daily"}/>
										<BookingVsCheckin Period={"Weekly"}/>
										<BookingVsCheckin Period={"Monthly"}/>
										<BookingVsCheckin Period={"Yearly"}/>
									</div>
								}
								<ChartReservation />
								<TableContributionER />
								<ChartRoomMonthly />
								<TableRoomYearly />
								{
									vBoolDev == true 
									?
									null
									:
									<>
										<ChartCCHMonthly />
										<TableCCHYearly />
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer/>
		</div>
	);
};

export default Reservation; 