import axios from "axios";
import React, { useEffect, useState } from "react";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { vAPIURL, vGlobalDateNow } from "../../Global";
import { Link } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faCalendarAlt, faCar } from "@fortawesome/free-solid-svg-icons";
import format from 'date-fns/format';
import './StyleMainPage.css';

const ViewPoint1 = () => {
	const [Loading, setLoading] = useState(false)
	const [MainPageData, setMainPageData] = useState([]);
	const date = new Date( vGlobalDateNow );

	const NOPData = MainPageData.map(Value => Value.NOP)
	const CaddyData = MainPageData.map(Value => Value.Caddy)
	const BuggyData = MainPageData.map(Value => Value.Buggy)
	const ReservationData = MainPageData.map(Value => Value.Reservation)

	const GetMainPageData = async() => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Main/DefaultPage";
			const vData = {
				Action: {
					Value: "Search"
				},
				Input: {
					Date: {
						Value: format( date, 'dd-MMM-yyyy' ),
					},
				},
			};

			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setMainPageData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false );
	}

	useEffect(() => {
		GetMainPageData()
	},[])

	return (
		<div className="col-12 col-lg-4">
			<div className="row">
				{
					Loading ?
						<div className="col-12">
							<div className="d-flex justify-content-center">
								<i className="fas fa-3x fa-sync-alt fa-spin"/>
							</div>
						</div>
						:
						<>
							<div className="col-6">
								<div className="small-box" id="Nop-Box">
									<div 
										className="inner"
										style={{ height: '100px' }}
									>
										<h4>
											{NOPData}
										</h4>
										<p style={{ fontSize: '12px'}}>
											TOTAL NOP
										</p>
									</div>
									<div className="icon">
										<PersonIcon />
									</div>
									<Link 
										className="small-box-footer"
										style={{cursor:'pointer'}}
										to={`/NumberOfPlayer`}
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<a>
											MORE INFO
										</a>
									</Link>
								</div>
							</div>
							<div className="col-6">
								<div className="small-box" id="Caddy-Box">
									<div 
										className="inner"
										style={{ height: '100px' }}
									>
										<h4>
											{CaddyData}
										</h4>
										<p style={{ fontSize: '12px'}}>
											TOTAL CADDY
										</p>
									</div>
									<div className="icon">
										<FontAwesomeIcon icon={faUsers} />
									</div>
									<Link 
										className="small-box-footer"
										style={{cursor:'pointer'}}
										to={`/GolfOperations`}
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<a>
											MORE INFO
										</a>
									</Link>
								</div>
							</div>
							<div className="col-6">
								<div className="small-box" id="Buggy-Box">
									<div 
										className="inner"
										style={{ height: '100px' }}
									>
										<h4>
											{BuggyData}
										</h4>
										<p style={{ fontSize: '12px'}}>
											TOTAL BUGGY ACTIVE
										</p>
									</div>
									<div className="icon">
										<FontAwesomeIcon icon={faCar} />
									</div>
									<Link 
										className="small-box-footer"
										style={{cursor:'pointer'}}
										to={`/GolfOperations`}
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<a>
											MORE INFO
										</a>
									</Link>
								</div>
							</div>
							<div className="col-6">
								<div className="small-box" id="Reservation-Box">
									<div 
										className="inner"
										style={{ height: '100px' }}
									>
										<h4>
											{ReservationData}
										</h4>
										<p style={{ fontSize: '12px'}}>
											TOTAL RESERVATION
										</p>
									</div>
									<div className="icon">
										<FontAwesomeIcon className="nav-icon" icon={faCalendarAlt} />
									</div>
									<Link 
										className="small-box-footer"
										style={{cursor:'pointer'}}
										to={`/Reservation`}
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<a>
											MORE INFO
										</a>
									</Link>
								</div>
							</div>
						</>
				}
			</div>
		</div>
	)
}

export default ViewPoint1