import React, { useEffect, useState } from 'react';
import './StyleMainPage.css';
import axios from 'axios';
import format from 'date-fns/format';
import Clouds from '../../Assets/WeatherIcon/clouds.gif';
import Cloudy from '../../Assets/WeatherIcon/cloudy.gif';
import Sun from '../../Assets/WeatherIcon/sun.gif';
import Foggy from '../../Assets/WeatherIcon/foggy.gif';
import Drizzle from '../../Assets/WeatherIcon/drizzle.gif';
import Cold from '../../Assets/WeatherIcon/cold.gif';
import SlightRain from '../../Assets/WeatherIcon/slight-rain.gif';
import Rain from '../../Assets/WeatherIcon/rain.gif';
import Snow from '../../Assets/WeatherIcon/snow.gif';
import ThunderRain from '../../Assets/WeatherIcon/thunder-rain.gif';

const Weather = () => {
	const [Loading, setLoading] = useState(true)
	const [Latitude, setLatitude] = useState(-6.2835402);
	const [Longitude, setLongitude] = useState(106.8925516,16);
	const [Weather, setWeather] = useState([]);
	const [Time, setTime] = useState( format( new Date(), 'HH:mm' ) );

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime( format( new Date(), 'HH:mm' ) );
		}, 1000);
		return () => clearInterval(intervalId);
	}, []);

	const WindDirection = (degrees) => {
		const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
		const index = Math.round((degrees % 360) / 45) % 8;
		return directions[index];
	};

	const WeatherStatus = (Code) => {
		const WMOCode = {
			0: 'CLEAR'
			, 1: 'MAINLY CLEAR'
			, 2: 'PARTLY CLOUDY'
			, 3: 'OVERCAST'
			, 45: 'FOG'
			, 48: 'DEPOSITING RIME FOG'
			, 51: 'LIGHT DRIZZLE'
			, 53: 'MODERATE DRIZZLE'
			, 55: 'DENSE DRIZZLE'
			, 56: 'LIGHT FREEZING'
			, 57: 'DENSE FREEZING'
			, 61: 'SLIGHT RAIN'
			, 63: 'MODERATE RAIN'
			, 65: 'HEAVY RAIN'
			, 66: 'LIGHT FREEZING RAIN'
			, 67: 'HEAVY FREEZING RAIN'
			, 71: 'SLIGHT SNOWFALL'
			, 73: 'MODERATE SNOWFALL'
			, 75: 'HEAVY SNOWFALL'
			, 77: 'SNOWGRAIN'
			, 80: 'SLIGHT RAINSHOWER'
			, 81: 'MODERATE RAINSHOWER'
			, 82: 'HEAVY RAINSHOWER'
			, 85: 'LIGHT SNOWSHOWERS'
			, 86: 'HEAVY SNOWSHOWERS'
			, 95: 'MODERATE THUNDERSTORM'
			, 96: 'HEAVY SNOWSHOWERS'
		};

		const status = WMOCode[Code];

		if (status === undefined) {
			console.error(`Weather status not found for WMO code: ${Code}`);
		}

		return status;
	};

	const WeatherIcon = (Code) => {
		const WMOCode = {
			0: Sun
			, 1: Sun
			, 2: Cloudy
			, 3: Clouds
			, 45: Foggy
			, 48: Foggy
			, 51: Drizzle
			, 53: Drizzle
			, 55: Drizzle // Foggy
			, 56: Cold
			, 57: Cold
			, 61: SlightRain
			, 63: Rain
			, 65: Rain
			, 66: Rain
			, 67: Rain
			, 71: Snow
			, 73: Snow
			, 75: Snow
			, 77: Rain
			, 80: Rain
			, 81: Rain
			, 82: Rain
			, 85: Rain
			, 86: Rain
			, 95: ThunderRain
			, 96: ThunderRain
		};
		const status = WMOCode[Code];
		return <img src={status}></img>;
	};

	const GetWeatherData = async() => {
		let vMessage = [];

		try{
			const vURL = "https://api.open-meteo.com/v1/forecast?latitude=" + Latitude + "&longitude=" + Longitude + "&current=temperature_2m,relative_humidity_2m,apparent_temperature,precipitation,weather_code,wind_speed_10m,wind_direction_10m,wind_gusts_10m&timezone=Asia%2FSingapore";
			await axios
				.get(vURL)
				.then(
					( vResponse ) => {
						setWeather( vResponse.data.current )
						Time = vResponse.data.current.time
					}
				)
				.catch(
					( vError ) => {
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
		}
		setLoading( false );
	}

	// useEffect(() => {
	// 	if ('geolocation' in navigator) {
	// 		navigator.geolocation.getCurrentPosition(
	// 			(position) => {
	// 				setLatitude(position.coords.latitude);
	// 				setLongitude(position.coords.longitude);
	// 			},
	// 			(error) => {
	// 				toastr.error( error.message );
	// 			}
	// 		);
	// 	}
	// 	else {
	// 		toastr.error('Geolocation is not supported');
	// 	}
	// },[])

	useEffect(() => {
		GetWeatherData()
	},[Latitude,Longitude])

	return (
		<div className="col-12 col-lg-8">
			<div className="card bg-gradient-light">
				<div className="card-header">
					<h3 className="card-title text-success font-weight-bold">
						CURRENT WEATHER
					</h3>
					<div className="card-tools">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								{Time}
							</li>
						</ul>
					</div>
				</div>
				<div className="card-body" id="WeatherBody">
					<div className="tab-content p-0">
						{
							Loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"/>
								</div>
							:
								<div className="row">
									<div className="col-6">
										<div className="row">
											<div className="col-6">
												<center id="Weather">
													{WeatherIcon(Weather.weather_code)}
												</center>
											</div>
											<div className="col-6">
												<p id="Degree">
													{Weather.temperature_2m}&deg;
													<span id="Celcius">
														C
													</span>
												</p>
												<p id="RealFeel">
													REALFEEL® {Weather.apparent_temperature}&deg;
												</p>
											</div>
											<div className="col-12 p-3">
												<div className="d-flex justify-content-center">
													<p style={{ fontSize: '20px' }}>
														{WeatherStatus(Weather.weather_code)}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="spaced-content detail">
											<span className="label">
												REALFEEL SHADE™
											</span>
											<span className="value">
												{Weather.apparent_temperature}°
											</span>
										</div>
										<div className="spaced-content detail">
											<span className="label">
												WIND
											</span>
											<span className="value">
												{WindDirection(Weather.wind_direction_10m)} {Weather.wind_speed_10m} km/h
											</span>
										</div>
										<div className="spaced-content detail">
											<span className="label">
												WIND GUSTS
											</span>
											<span className="value">
												{Weather.wind_gusts_10m} km/h
											</span>
										</div>
									</div>
								</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Weather